@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer components {}

#cursor {
  animation: blink 1s 10;
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes objectEase {
  0% {
    transform: translateX(-5vw);
    opacity: 0.4
  }

  100% {
    transform: translateX(0vw);
    opacity: 1;
  }
}

#p1 {
  animation: objectEase 1s;
}


#p2 {
  animation: objectEase 1.5s;
}

#p3 {
  animation: objectEase 2s;
}

#p4 {
  animation: objectEase 2.5s;
}

#p5 {
  animation: objectEase 3s;
}


/* width */


.scrollable-container {
  overflow-y: auto;
  /* Allow vertical scrolling */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */

  /* For WebKit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
  }

  /* For WebKit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(200, 195, 195, 0.361);
    /* Set the color of the scrollbar thumb */
    border-radius: 1rem;
  }

  /* &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active  {
    background-color: rgba(226, 0, 0, 0.5); 
  } */
}

@layer utilities {
  .html {
    scroll-behavior: smooth;
  }

  .animateElement {
    opacity: 0;
    transition: all 0.5s;
    transform: translateX(-5vh);
  }

  .posLeft {
    opacity: 0;
    transition: all 0.5s;
    transform: translateX(-5vw) translateY(-10vh);

  }

  .posRight {
    opacity: 0;
    transition: all 0.5s;
    transform: translateX(5vw) translateY(10vh);


  }

  /* show must appear at the bottom as css overrites */
  .show {
    opacity: 1;
    transition: all 2s;
    filter: blur(0);
    transform: translateX(0);
    transform: translateY(0);

  }

  .transition-slide {
    opacity: 1;
    transition: all 2s;
    transform: translateX(0);
    position: absolute;
  }

  .transition-none {
    opacity: 0.8;
    transition: ease-in-out 2s;
    transform: translateX(-5vw);
    position: absolute;

  }

  .glass_bg {
    background-color: rgb(255 255 255 / 0.3);
    --tw-bg-opacity: 0.2;
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    border-radius: 0.75rem
      /* 12px */
    ;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 3rem
      /* 20px */
    ;
  }

  .selected {
    background-color: rgba(98, 98, 98, 0.562);
    border-color: rgb(86, 88, 99);
    /* box-shadow : 10px 10px white; */
    --tw-shadow: 0 25px 50px -12px rgba(233, 233, 233, 1);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .error {
    border-color: rgb(234, 48, 38);
  }

  .scrollbar {
    &::-webkit-scrollbar-track {
      /* border-radius: 100vh; */
      background: transparent;
      width: 10px;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #636262;
      border-radius: 1rem;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #7f7e7e;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
  }

  .slide-out {
    animation: slideOut 0.5s ease forwards;
  }

  .newElement {
    content: "Before you get started, we would like to know more to personalise your experience.";
    display: relative;
    top: 2.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: rgb(0 0 0 / 0.35);
    animation: slideIn 1s ease forwards;
  }

  .animateButtonPop{
   animation: pop 0.15s ease-in-out
  }

  .rpv-core__inner-page {
    background-color: #0000;
 }
  .slide-in-left{
    animation: slideInFromLeft 0.5s  forwards;
  }

  .slide-out-right{
    animation: slideOutFromRight 0.5s forwards;
  }


  @keyframes slideOut {
    0% {
      transform: translateX(0);
      opacity: 100%;
    }

    100% {
      transform: translateX(-25%);
      opacity: 0%;
      display: none;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(50%);
      opacity: 50%;
    }

    100% {
      transform: translateX(0);
      opacity: 100%;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-50%);
      opacity: 80%;
    }

    100% {
      transform: translateX(0);
      opacity: 100%;
    }
  }

  @keyframes slideOutFromRight {
    0% {
      transform: translateX(0%);
      opacity: 100%;
    }

    100% {
      transform: translateX(-50%);
      opacity: 0%;
    }
  }

  @keyframes pop {
    0%{
      transform: scale(0.75);
      opacity: 25%;
    }
    75%{
      transform:scale(1.1);
      opacity: 90%;
    }

    100%{
      transform:scale(1);
      opacity: 1;
    }
  }
}